import React from "react"
import Header from "./header"
import Image from "./image"
import styles from './layout.module.scss'
import { FirebaseContext, useAuth } from './firebase'
import CountDown from "./countDown"
import {Helmet} from 'react-helmet'

const Layout = ({ children }) => {

  const { user, firebase, loading } = useAuth();
  const location = typeof window !== 'undefined' ? window.location.pathname : '';
  const notLogin = location !== '/';
 
  return (
    <FirebaseContext.Provider value={{ user, firebase, loading }}>
      <div style={{ margin: `0`, box: `border-box` }}>
      <Helmet>
        <html lang='fi' />
        <title>Nuoripari</title>
        <meta name='description' />
      </Helmet>
        {notLogin &&
          <Header className={styles.header}>
          </Header>
        }
        <div className={styles.titlewrap}>
          <div className={styles.imgwrap2}>
            <Image  ></Image>
          </div >
          <div className={styles.titletext}>
            <div>Annakaisa ja Markku</div>
            <div className={styles.date}>28.5.2022</div>
          </div>
        </div>

        {children}

        {notLogin &&
          <CountDown></CountDown>
        }
        {notLogin &&
            <div className={styles.footer}> <span>&copy;</span> Monsieur Lehtonen</div>
        }
      </div>
    </FirebaseContext.Provider>
  )
}
export default Layout;
import React, { useContext } from "react"
import { AppBar, Toolbar } from "@material-ui/core"
import { List, ListItem, ListItemText } from "@material-ui/core"
import { IconButton, Button } from "@material-ui/core"
import { Home } from "@material-ui/icons"
import { Container, Hidden } from "@material-ui/core"
import SideDrawer from "./sideDrawer"
import { Link, navigate } from "gatsby"
import styles from './header.module.scss'
import FirebaseContext from "./firebase/context";

const navLinks = [
    { id: 0, title: `Hääinfo`, path: `../../app/info` },
    { id: 1, title: `Muistaminen`, path: `../../app/muistaminen` },
    { id: 2, title: `Laulutoive`, path: `../../app/laulutoive` },
    { id: 3, title: `Tamara K. click here`, path: `../../app/tamara` }
];

const activeStyles = {
    textDecoration: 'underline'
}

const Header = () => {
    const { firebase, user } = useContext(FirebaseContext);

    function handleLogoutClick() {
        firebase.logout().then(() => {
            window.localStorage.removeItem("user");
            navigate('/');
        })
    }

    return (
        <AppBar position="static">
            <Toolbar className={styles.navbar}>
                <Container className={styles.navbarDisplayFlex}>

                    <IconButton edge="start" aria-label="home" to="/app/tervetuloa" component={Link}>
                        <Home fontSize="large" style={{ color: 'inherit' }}/>
                    </IconButton>
                    <Hidden smDown >
                        <List component="nav" aria-labelledby="main navigation"
                            className={styles.navDisplayFlex}>
                            {navLinks.map(({ id, title, path }) => (
                                <Link key={id.toString()} to={path} className={styles.linkText} activeStyle={activeStyles}>
                                    <ListItem buttonstyle={{}}>
                                        <ListItemText primary={title} />
                                    </ListItem>
                                </Link>
                            ))}
                        </List>
                        <div>
                            {!!user && !!user.email &&
                                <div>
                                    Tervetuloa, {user.name || user.email}
                                    <div className={styles.logoutwrapper}>
                                        <Button className={styles.logout} onClick={handleLogoutClick} >
                                            Kirjaudu ulos
                                        </Button>
                                    </div>
                                </div>
                            }
                            {(!user || !user.email) &&
                                <div className={styles.login}>
                                    <Link to="/">Kirjaudu sisään
                                    </Link>
                                </div>
                            }
                        </div>
                    </Hidden>
                    <Hidden mdUp>
                        <SideDrawer navLinks={navLinks} />
                    </Hidden>
                </Container>
            </Toolbar>
        </AppBar>
    )
}
export default Header
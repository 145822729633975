import React, { useEffect, useState } from "react";
import styles from './countDown.module.scss'

const CountDown = () => {
  
  function calculateCountdown(endDate) {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

    // clear countdown when date is reached
    if (diff <= 0) return false;

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      millisec: 0,
    };

    // calculate time difference between now and expected date
    if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) { // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) { // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  }

  const [timeLeft, setTimeLeft] = useState(calculateCountdown());
  const endDate = new Date('May 28, 2022 14:00:00');

  useEffect(() => {
    let isMounted = true;
    setTimeout(() => {
      if (isMounted) 
      setTimeLeft(calculateCountdown(endDate));
    }, 1000);
    return () => { isMounted = false }; // use effect cleanup
  });

  function addLeadingZeros(value) {
    value = String(value);
    while (value.length < 2) {
      value = '0' + value;
    }
    return value;
  }

  return (
    <div className={styles.Countdown}>
        <span className={styles.CountdownCol}>
          <span className={styles.CountdownColElement}>
              <strong>{addLeadingZeros(timeLeft.days)}</strong>
              <span>{timeLeft.days === 1 ? 'päivä' : 'päivää'}</span>
          </span>
        </span>

        <span className={styles.CountdownCol}>
          <span className={styles.CountdownColElement}>
            <strong>{addLeadingZeros(timeLeft.hours)}</strong>
            <span>tuntia</span>
          </span>
        </span>

        <span className={styles.CountdownCol}>
          <span className={styles.CountdownColElement}>
            <strong>{addLeadingZeros(timeLeft.min)}</strong>
            <span>minuuttia</span>
          </span>
        </span>

        <span className={styles.CountdownCol}>
          <span className={styles.CountdownColElement}>
            <strong>{addLeadingZeros(timeLeft.sec)}</strong>
            <span>sekuntia</span>
          </span>
        </span>
      </div>
)
};

export default CountDown;

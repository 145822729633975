// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-info-js": () => import("./../../../src/pages/app/info.js" /* webpackChunkName: "component---src-pages-app-info-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-app-laulutoive-js": () => import("./../../../src/pages/app/laulutoive.js" /* webpackChunkName: "component---src-pages-app-laulutoive-js" */),
  "component---src-pages-app-login-js": () => import("./../../../src/pages/app/login.js" /* webpackChunkName: "component---src-pages-app-login-js" */),
  "component---src-pages-app-muistaminen-js": () => import("./../../../src/pages/app/muistaminen.js" /* webpackChunkName: "component---src-pages-app-muistaminen-js" */),
  "component---src-pages-app-tamara-js": () => import("./../../../src/pages/app/tamara.js" /* webpackChunkName: "component---src-pages-app-tamara-js" */),
  "component---src-pages-app-tervetuloa-js": () => import("./../../../src/pages/app/tervetuloa.js" /* webpackChunkName: "component---src-pages-app-tervetuloa-js" */),
  "component---src-pages-app-uusiyritys-js": () => import("./../../../src/pages/app/uusiyritys.js" /* webpackChunkName: "component---src-pages-app-uusiyritys-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}


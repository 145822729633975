import firebaseConfig from "./config";

class Firebase {
  constructor(app) {
    if(!firebaseInstance) {
      app.initializeApp(firebaseConfig);

      this.auth = app.auth();
      this.db = app.firestore();
      this.functions = app.functions();
      this.storage = app.storage();
    }
  }

  async getUserProfile({userId}) {
    return this.db.collection('publicProfiles').where('userId', '==', userId).get();
  }


  async login({email, password}) {
    return this.auth.signInWithEmailAndPassword(email, password)
    
  }

  async logout() {
    await this.auth.signOut();
  }

  async addSong(data) {
    console.log('adding', data);
    console.log(this.auth);
    console.log('logged',data.loggedIn !== null);
    if (data.loggedIn === null) {
        console.log('Cannot add song, Not logged in');
        throw new Error('not logged in');
    }
    this.db.collection("laulut").add({
      toivoja: data.toivoja,
      kappale: data.kappale,
      soittaja: data.soittaja
  })
  .then(function() {
      console.log("Document successfully written!");
  })
  .catch(function(error) {
      console.error("Error writing document: ", error);
  });

  }
}

let firebaseInstance;

function getFirebaseInstance(app) {
  if(!firebaseInstance && app){
    firebaseInstance = new Firebase(app);
    return firebaseInstance;
  }else if(firebaseInstance){
    return firebaseInstance
  }else{
    return null;
  }
}

export default getFirebaseInstance;

const firebaseConfig = {
    apiKey: "AIzaSyCKspXMRYDgw9Nsvw9s6JwS8HKYkH2QXa8",
    authDomain: "spectacleapp-1b6d7.firebaseapp.com",
    databaseURL: "https://spectacleapp-1b6d7.firebaseio.com",
    projectId: "spectacleapp-1b6d7",
    storageBucket: "spectacleapp-1b6d7.appspot.com",
    messagingSenderId: "255339883248",
    appId: "1:255339883248:web:59b73463309020769c3035",
    measurementId: "G-9ZCWXT4RQZ"
  };
  export default firebaseConfig;
import React, { useContext } from "react"
import { IconButton } from "@material-ui/core"
import { Menu, ExitToApp } from "@material-ui/icons"
import { useState } from "react"
import { List, ListItem, ListItemText } from "@material-ui/core"
import { Drawer } from '@material-ui/core'
import { Link, navigate } from "gatsby"
import styles from './sideDrawer.module.scss'
import FirebaseContext from "./firebase/context";

const SideDrawer = ({ navLinks}) => {
  const [state, setState] = useState({ right: false })
  const { firebase } = useContext(FirebaseContext);
  const logoutPage = () => {
    
    firebase.logout().then(() => {
      console.log('logging out, empty storage');
      window.localStorage.removeItem("user");
      navigate('/');
  })
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setState({ [anchor]: open })
  };

  const sideDrawerList = anchor => (
    <div
      className={styles.list} 
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List component="nav">
        {navLinks.map(({id, title, path }) => (
          <Link key={id.toString()} to={path} className={styles.linkText}>
            <ListItem button>
              <ListItemText primary={title} />
            </ListItem>
          </Link>
        ))}
      </List>
      <IconButton edge="start" aria-label="logout" onClick={()=>logoutPage()}>
                        <ExitToApp fontSize="large" style={{color: 'inherit', marginLeft: '0.5em' }}/>
      </IconButton>
      
    </div>
  );
  return (
    <React.Fragment>
      <IconButton
        edge="start"
        aria-label="menu"
        onClick={toggleDrawer("right", true)}
      >
        <Menu fontSize="large" style={{ color: 'inherit' }}/>
      </IconButton>
      <Drawer
        anchor="right"
        open={state.right}
        onOpen={toggleDrawer("right", true)}
        onClose={toggleDrawer("right", false)}
      >
        {sideDrawerList("right")}
      </Drawer>
    </React.Fragment>
  )
}
export default SideDrawer